<template>
  <div class="total-block" v-if="isDataLoaded">
    <el-table
      class="card-summary-table card-section-elements-table"
      header-row-class-name="card-section-elements-table-header"
      style="width: 100%; margin-bottom: 25px"
      :data="totalEvaluate"
    >
      <el-table-column :label="titleName"></el-table-column>
      <el-table-column :label="evaluationHeader" width="450">
        <template v-slot="scope">
          <el-select
            :disabled="!$canAndRulesPass(card, permission)"
            v-model="scope.row.evaluate"
            placeholder="Выберите оценку"
            @change="updateEvaluation(scope.row.evaluate)"
          >
            <el-option
              v-for="entityEvaluation in entityEvaluations"
              :key="entityEvaluation.id"
              :label="entityEvaluation.name"
              :value="entityEvaluation.id"
            >
            </el-option>
          </el-select>
          <el-popover
            placement="top"
            title="Описание"
            width="400"
            trigger="click"
          >
            <el-button
              slot="reference"
              class="table-action-button open-desc-button"
              type="text"
              round
              size="mini"
              icon="fas fa-info-circle"
            ></el-button>
            <div style="white-space: pre-line">{{ getDescription(scope.row.evaluate) }}</div>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div v-else>Loading...</div>
</template>

<script>
import { mapGetters } from "vuex";
import requestSender from "@/api/base/requestSender";

export default {
  name: "total-evaluate-entity-",
  computed: {
    ...mapGetters('card', {
      card: 'card'
    }),
    isDataLoaded() {
      return this.card && this.card.info;
    },
    totalEvaluate() {
      if (this.isDataLoaded) {
        const evaluateValue = this.card.info[this.evaluateKey];
        return [{evaluate: evaluateValue}];
      }
      return [];
    },
    evaluationHeader() {
      if (this.evaluationName) {
        return this.evaluationName;
      }

      return this.isDataLoaded ? this.$companyConfiguration.settings.getEnum('evaluation_headers')[this.entityEvaluateKey] : '';
    }
  },
  props: {
    permission: {
      type: String,
      required: true,
    },
    titleName: {
      type: String,
      required: true,
    },
    entityEvaluateKey: {
      type: Number,
      required: true,
    },
    entityEvaluations: {
      type: Array,
      required: true,
    },
    evaluateKey: {
      type: String,
      required: true,
    },
    evaluationName: {
      type: String,
      required: false,
    },
  },
  methods: {
    getDescription(evaluation_id) {
      const evaluation = this.entityEvaluations.find(e => e.id === evaluation_id);
      return evaluation
        ? evaluation.description ? evaluation.description : 'Нет описания'
        : 'Оценка не выбрана';
    },
    updateEvaluation(evaluate) {
      requestSender('post', 'card/total-evaluate-competence', {
        card_id: this.card.id,
        evaluation: this.evaluateKey,
        evaluation_id: evaluate,
      })
        .then(() => {
          this.$notify.success({
            title: 'Оценено',
            message: 'Оценка успешно выставлена',
          });
        })
    }
  }
}
</script>

<style scoped>
  .total-block {
    margin-top: 30px;
  }
</style>
